export const getSarahsMarkers = function(): google.maps.Marker[] { 
return [
  new google.maps.Marker({
    position: {
      lat: 41.4976393,
      lng: -81.7758995
    },
    // label: 'Cleveland, OH, USA
  }), new google.maps.Marker({
    position: {
      lat: 34.0304525,
      lng: -118.4837512
    },
    // label: 'The Ambrose Hotel Santa Monica - Santa Monica, CA'
  }), new google.maps.Marker({
    position: {
      lat: 33.7613799,
      lng: -84.3895443
    },
    // label: 'Hyatt Regency Atlanta - Atlanta, GA'
  }), new google.maps.Marker({
    position: {
      lat: -17.4854572,
      lng: -149.8465007
    },
    // label: 'Hilton Moorea Lagoon Resort & Spa - Moorea-Maiao, French Polynesia'
  }), new google.maps.Marker({
    position: {
      lat: 20.7849155,
      lng: -156.4675133
    },
    // label: 'Sugar Beach Resorts - Kihei, HI, USA'
  }), new google.maps.Marker({
    position: {   
      lat: 41.8975871,
      lng: 12.4790141
    },
    // label: 'Hotel Grazioli - Rome, Italy'
  }), new google.maps.Marker({
    position: {
      lat: 40.6284694,
      lng: 14.4813942
    },
    // label: 'Villa Yiara - Positano SA, Italy' -- not sure if this is the correct place
  }), new google.maps.Marker({
    position: {
      lat: 45.4303037,
      lng: 12.3275713
    },
    // label: 'Hotel American Dinesen - Venezia VE, Italy'
  }), new google.maps.Marker({
    position: {
      lat: 44.4110106,
      lng: 8.9242565
    },
    // label: 'NH Collection Genova Marina - Genova GE, Italy'
  }), new google.maps.Marker({
    position: {
      lat: 45.4925974,
      lng: 10.6067628
    },
    // label: 'Hotel Eden - Sirmione BS, Italy'
  }), new google.maps.Marker({
    position: {
      lat: 45.9875654,
      lng: 9.2605369
    },
    // label: 'Hotel du Lac - Bellagio CO, Italy'
  }), new google.maps.Marker({
    position: {
      lat: 46.5414493,
      lng: 12.1330051
    },
    // label: 'Hotel Europa - Cortina d'Ampezzo BL, Italy'
  }), new google.maps.Marker({
    position: {
      lat: 48.8544697,
      lng: 2.2955452
    },
    // label: '40 Av. de Suffren - Paris, France'
  }), new google.maps.Marker({
    position: {
      lat: -36.8493973,
      lng: 174.7734165
    },
    // label: 'Copthorne Hotel Auckland City - Auckland, New Zealand'
  }), new google.maps.Marker({
    position: {
      lat: 7.8596917,
      lng: 98.2866188
    },
    // label: 'Centara Villas Phuket - Phuket, Thailand'
  }), new google.maps.Marker({
    position: {
      lat: -33.8801279,
      lng: 151.2137483
    },
    // label: 'Cambridge Hotel Sydney - NSW, Australia'
  }), new google.maps.Marker({
    position: {
      lat: -33.9052009,
      lng: 151.2246787
    },
    // label: '64-66 Anzac Parade, Kensington NSW 2033, Australia'
  }), new google.maps.Marker({
    position: {
      lat: 51.5134786,
      lng: -0.1239325
    },
    // label: '10 Hanover Pl, London WC2E 9DD, UK'
  }), new google.maps.Marker({
    position: {
      lat: 51.476477,
      lng: -3.1763034
    },
    // label: 'Clayton Hotel Cardiff - Cardiff, United Kingdom'
  }), new google.maps.Marker({
    position: {
      lat: 52.4473098,
      lng: -1.7150676
    },
    // label: 'Pendigo Way, Marston Green, Birmingham B40 1PU, United Kingdom' 
  }), new google.maps.Marker({
    position: {
      lat: 63.9840072,
      lng: -22.7315931
    },
    // label: 'Keflavík Airport - Keflavík, Iceland'
  }), new google.maps.Marker({
    position: {
      lat: 41.387776,
      lng: 2.1655083
    },
    // label: 'H10 Metropolitan - Barcelona, Spain'
  }), new google.maps.Marker({
    position: {
      lat: 37.3878867,
      lng: -5.9926765
    },
    // label: 'C. Francos, 59-65, 41004 Sevilla, Spain'
  }), new google.maps.Marker({
    position: {
      lat: 38.9104559,
      lng: -77.0442199
    },
    // label: 'The Dupont Circle Hotel - Washington, DC, USA'
  }), new google.maps.Marker({
    position: {
      lat: 43.6461541,
      lng: -79.3945381
    },
    // label: 'Toronto, ON M5V 0K4, Canada';
  }), new google.maps.Marker({
    position: {
      lat: 39.7790247,
      lng: -86.1669384
    },
    // label: '9 on Canal - Indianapolis, IN, USA'
  }), new google.maps.Marker({
    position: {
      lat: 41.884484,
      lng: -87.6290509
    },
    // label: 'Cambria Hotel Chicago Loop - Theatre District - Chicago, IL, USA'
  }), new google.maps.Marker({
    position: {
      lat: 36.193967,
      lng: -86.740899
    },
    // label: '1504 Ward Ave, Nashville, TN 37206'
  }), new google.maps.Marker({
    position: {
      lat: 36.2378763,
      lng: -80.8234173
    },
    // label: 'Hampton Inn Jonesville/Elkin - Jonesville, NC, USA'
  }), new google.maps.Marker({
    position: {
      lat: 26.4511634,
      lng: -81.9539462
    },
    // label: 'Lani Kai Island Resort - Fort Myers Beach, FL, USA'
  }), new google.maps.Marker({
    position: {
      lat: 27.9469791,
      lng: -82.4619709
    },
    // label: 'Aloft Tampa Downtown - Tampa, FL, USA'
  }), new google.maps.Marker({
    position: {
      lat: 28.326181,
      lng: -81.5171632
    },
    // label: 'CLC Regal Oaks Resort - Kissimmee, FL, USA'
  }), new google.maps.Marker({
    position: {
      lat: 38.8911091,
      lng: -77.0897244
    },
    // label: 'Hyatt Place Arlington/Courthouse Plaza - Arlington, VA, USA'
  }), new google.maps.Marker({
    position: {
      lat: 39.5092393,
      lng: -82.5140381
    },
    // label: '27700 Cook Rd, Logan, OH 43138'
  }), new google.maps.Marker({
    position: {
      lat: 40.4919249,
      lng: -80.237412
    },
    // label: 'Pittsburgh International Airport - Pittsburgh, PA, USA'
  }), new google.maps.Marker({
    position: {
      lat: 52.5073384,
      lng: 13.3468608
    },
    // label: 'InterContinental Berlin - Berlin, Germany'
  }), new google.maps.Marker({
    position: {
      lat: 49.4470447,
      lng: 11.0849271
    },
    // label: 'Hampton by Hilton Nuremberg City Centre - Nuremberg, Germany'
  }), new google.maps.Marker({
    position: {
      lat: 49.3800422,
      lng: 10.1848958
    },
    // label: 'Villa Mittermeier - Rothenburg ob der Tauber, Germany' -- i think this is right?
  }), new google.maps.Marker({
    position: {
      lat: 50.1046022,
      lng: 8.664498
    },
    // label: 'Roomers - Frankfurt, Germany'
  }), new google.maps.Marker({
    position: {
      lat: 53.3428352,
      lng: -6.2727085
    },
    // label: 'Jurys Inn Christchurch Dublin - Dublin 8, Ireland'
  }), new google.maps.Marker({
    position: {
      lat: 53.3647059,
      lng: -6.2088942
    },
    // label: 'Clontarf Castle Hotel - Dublin 3, Ireland' 
  }), new google.maps.Marker({
    position: {
      lat: 52.6532155,
      lng: -7.2427886
    },
    // label: 'Carraig Rua - Kilkenny, Ireland' -- is this right?
  })];
};
