import React, { useEffect, useState } from "react";

const useFullscreenState = function (setShouldBeFullscreen: React.Dispatch<React.SetStateAction<boolean>>): boolean {
  const [ isFullscreen, setIsFullscreen ] = useState(!!document.fullscreenElement);
  useEffect(() => {
    const fullscreenChangeEventHandler = () => {
      const fullscreen = !!document.fullscreenElement;
      setIsFullscreen(fullscreen);
      setShouldBeFullscreen(fullscreen);
    };
  
    document.addEventListener('fullscreenchange', fullscreenChangeEventHandler);
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChangeEventHandler);
    };
  });

  return isFullscreen;
};

const useSetFullscreen = function (): React.Dispatch<React.SetStateAction<boolean>> {
  const [ isFullscreen, setIsFullscreen ] = useState(!!document.fullscreenElement);  
  useEffect(() => {
    if (isFullscreen) {
      if (!document.fullscreenElement) {
        try { document.body.requestFullscreen(); } catch {}      
      }
    } else {
      if (!!document.fullscreenElement) {
        document.exitFullscreen();
      }
    }
  }, [isFullscreen]);

  return setIsFullscreen;
};

export const useFullscreen = function (): [ boolean, React.Dispatch<React.SetStateAction<boolean>> ] {
  const setShouldBeFullscreen = useSetFullscreen();
  return [ useFullscreenState(setShouldBeFullscreen), setShouldBeFullscreen ];
};
