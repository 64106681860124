import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMap } from '../hooks/use-map';
import styles from './Map.module.scss';

export interface MapProps {
  heatmap?: google.maps.LatLng[];
  mapRef?: React.Dispatch<React.SetStateAction<google.maps.Map | undefined>>;
  markers?: google.maps.Marker[];
  options?: google.maps.MapOptions;
}

export function Map(props: MapProps) {
  const [ mapRef, , map ] = useMap(props.options);
  const [ heatmapLayer, setHeatmapLayer] = useState<google.maps.visualization.HeatmapLayer | undefined>();

  useEffect(() => {
    if (!map) { return; }

    if (props.mapRef) {
      props.mapRef(map);
    }

    if (props.markers) {
      props.markers.forEach(marker => {
        marker.setMap(map);
      });
    }

    if (props.heatmap) {
      if (!heatmapLayer) {
        setHeatmapLayer(new google.maps.visualization.HeatmapLayer({
          data: props.heatmap,
          gradient: [
            "rgba(74, 20, 140, 0)",
            "rgba(74, 20, 140, 1)",
            "rgba(106, 27, 154, 1)",
            "rgba(171, 71, 188, 1)",
          ],
          map,
          radius: 10
        }));
      } else {
        heatmapLayer.setData(props.heatmap);
        heatmapLayer.setMap(map);
      }
    } else if (heatmapLayer) {
      heatmapLayer.setData([]);
      heatmapLayer.setMap(null);
    }
  }, [map, heatmapLayer, props]);

  return <>
    {!map && <Skeleton variant="rectangular" animation="wave" height="100%" width="100%" />}
    <div className={styles.container} ref={mapRef} style={{ display: map ? "initial" : "none" }} />
  </>;
}
