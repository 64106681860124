import { useEffect, useState } from 'react';
import { 
  Divider,
  Drawer, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Typography
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import { Map } from './components/Map';
import { getSarahsMarkers } from './config/sarahs-markers';
import { useFullscreen } from './hooks/use-fullscreen';

import './App.scss';
import { useMarkers } from './hooks/use-markers';

// TODO: apparently you can't change the map Id after the map has been constructed. Maybe there's one map Id with multiple style types?
const DAY_WALL_DISPLAY_MAP_ID = "269fd63a113a9b50";
// const NIGHT_WALL_DISPLAY_MAP_ID = "44266f457f77326d";

const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
  center: { 
    lat: 0, 
    lng: 0
  },
  disableDefaultUI: true,
  mapId: DAY_WALL_DISPLAY_MAP_ID,
  zoom: 2
};

/**
 * Reminders:
 *  Google Map APIs - https://console.cloud.google.com/google/maps-apis/studio/maps?project=chaoticgoodshelper
 *  Google map api is attached to the wrong project.
 *  It might be best to just pull in all of the user's data and create a heat map or pin things that are hotels.
 *  Custom popups - https://developers.google.com/maps/documentation/javascript/examples/overlay-popup
 *  Maybe use the custom popups to create more in-depth info about her trips.
 *  Change the icons.
 */

type MarkerType = 'marker' | 'heatmap';

function App() {
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ heatmap, setHeatmap ] = useState<google.maps.LatLng[] | undefined>();
  const [ map, setMap ] = useState<google.maps.Map | undefined>();
  const [ markers, setMarkers ] = useState<google.maps.Marker[] | undefined>();
  const [ markerType, setMarkerType ] = useState<MarkerType>('marker');
  const [ isFullscreen, setFullscreen ] = useFullscreen();

  const apiMarkers = useMarkers();

  useEffect(() => {
    if (apiMarkers && map && !markers) {
      const sarahsMarkers = getSarahsMarkers();
      setMarkers([
        ...sarahsMarkers, 
        ...apiMarkers.map(m => new google.maps.Marker({ 
          position: {
            lat: m.latitude,
            lng: m.longitude
          },
          label: m.label
        }))
      ]);
      setHeatmap(sarahsMarkers.map(m => m.getPosition()).filter(m => !!m) as google.maps.LatLng[]);
    }
  }, [apiMarkers, map, markers]);

  // todo: this should be handled by the map component
  useEffect(() => {
    switch (markerType) {
      case 'heatmap':
        markers && markers.forEach(m => m.setMap(null));
        break;
    }
  }, [heatmap, markers, markerType]);

  return <div className="app-container">
    <div className="buttons">      
      <IconButton color="primary" onClick={() => setDrawerOpen(true)}>
        <MenuOpenIcon />
      </IconButton>

      {!isFullscreen && <IconButton color="primary" onClick={() => setFullscreen(true)}>
        <FullscreenIcon />
      </IconButton>}
      {isFullscreen && <IconButton color="primary" onClick={() => setFullscreen(false)}>
        <FullscreenExitIcon />
      </IconButton>}
    </div>
  
    {/* TODO: move this to its own component */}
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}>
      <div className="drawer-contents">
        <Typography variant="h5" component="div" gutterBottom>
          Travel Map Options
        </Typography>

        {/* I don't know how I feel about the middle variant */}
        <Divider variant="middle" />

        <FormControl component="fieldset">
          <FormLabel component="legend">Marker type</FormLabel>
          <RadioGroup
            aria-label="Marker type"
            defaultValue="marker"
            name="marker-type-group"
            value={markerType}
            onChange={(event) => setMarkerType(event.target.value as MarkerType)}>
            <FormControlLabel 
              control={<Radio />} 
              label="Markers"
              value="marker" />
            <FormControlLabel 
              control={<Radio />}
              label="Heatmap"
              value="heatmap" />
          </RadioGroup>
        </FormControl>
      </div>
    </Drawer>

    <Map 
      heatmap={markerType === 'heatmap' ? heatmap : undefined}
      mapRef={setMap}
      markers={markerType === 'marker' ? markers : undefined}
      options={DEFAULT_MAP_OPTIONS} />
  </div>;
}

export default App;
