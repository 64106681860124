import { useCallback, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

type NullableVoidCallback<T> = (arg0: T | null) => void;
type Undefinable<T> = T | undefined;

// todo: allow for the props to update the map object
export const useMap = (options?: google.maps.MapOptions): [ NullableVoidCallback<HTMLDivElement>, Undefinable<Loader>, Undefinable<google.maps.Map> ] => {
  const [ loader, setLoader ] = useState<undefined | Loader>(undefined);
  const [ map, setMap ] = useState<undefined | google.maps.Map>(undefined);
  
  const mapRef = useCallback((node: HTMLDivElement | null) => {
    const setupLoader = (mapElement: HTMLDivElement) => {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
        version: "weekly",
        libraries: [
          'visualization'
        ]
      });
      
      loader.load().then(() => {
        const map = new google.maps.Map(mapElement, options);
        setMap(map);
      });
  
      setLoader(loader);
    };

    if (node) {
      if (!loader || !map) {
        setupLoader(node);
      } else {
        if (options) {
          map.setOptions(options);
        }
      }
    }
  }, [loader, map, options]);

  return [ mapRef, loader, map ];
}
