import { useEffect, useState } from "react";

export interface Marker {
  label: string;
  latitude: number;
  longitude: number;
}

export const useMarkers = () => {
  const [ markers, setMarkers ] = useState<Marker[]>([]);

  useEffect(() => {
    fetch('https://us-central1-wallmountedworldmap.cloudfunctions.net/get-markers').then(response => {
      response.json().then(markers => {
        setMarkers((markers as any[]).map(m => {
          return {
            label: m.Label,
            latitude: m.Latitude,
            longitude: m.Longitude
          } as Marker;
        }));
      });
    });
  }, []);

  return markers;
};
